import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    campId: '40613-120-13-101.1',
    questions: {
      1: 'Warum klappern Störche?',
      2: 'Wie nannte der Erfinder Dr. Bircher-Benner das Bircher-Müesli?',
      3: 'Für welche Sammelaktion engagiert sich Stefanie Heinzmann?',
      4: 'In welchen See mündet der 400 Meter hohe Giessbachfall?',
      5: 'Welches Museum besucht Globi in «Globi im Museum»?',
    },
    answers: [
      {
        step: '1',
        options: [
          {
            value: 'Zur Abschreckung',
            rightAnswer: true,
          },
          {
            value: 'Zur Begrüssung',
            rightAnswer: true,
          },
        ],
      },
      {
        step: '2',
        options: [
          {
            value: 'Cerealienmischkost',
            rightAnswer: false,
          },
          {
            value: 'Apfeldiätspeise',
            rightAnswer: true,
          },
        ],
      },
      {
        step: '3',
        options: [
          {
            value: 'Sternenwochen',
            rightAnswer: true,
          },
          {
            value: 'Mondmonate',
            rightAnswer: false,
          },
        ],
      },
      {
        step: '4',
        options: [
          {
            value: 'Brienzersee',
            rightAnswer: true,
          },
          {
            value: 'Thunersee',
            rightAnswer: false,
          },
        ],
      },
      {
        step: '5',
        options: [
          {
            value: 'Museum Paul Klee',
            rightAnswer: false,
          },
          {
            value: 'Landesmuseum',
            rightAnswer: true,
          },
        ],
      },
    ],
    userAnswers: [],
    resultText: [
      {
        count: '0',
        text: {
          headline: 'Leider nichts!',
          paragraph:
            'Damit Sie dieses vielseitige Land besser kennen lernen, schenken wir Ihnen als Sofortpreis 3 Ausgaben der «Schweizer Familie. Und natürlich haben Sie immer noch die Chance auf unseren Hauptpreis: drei luxuriöse Nächte im Hotel Vitznauerhof am Vierwaldstättersee.',
        },
      },
      {
        count: '1',
        text: {
          headline: 'Besser als nichts!',
          paragraph:
            'Es ist nie zu spät, dieses vielseitige Land besser kennenzulernen. Als Sofortpreis schenken wir Ihnen 3 Ausgaben der «Schweizer Familie». Und natürlich haben Sie immer noch die Chance auf unseren Hauptpreis: drei Nächte im historischen Hotel La Couronne im Herzen der Solothurner Altstadt.',
        },
      },
      {
        count: '2',
        text: {
          headline: 'Fast die Hälfte gewusst!',
          paragraph:
            'Die Schweiz ist so vielseitig, dass man immer wieder Neues entdecken kann. Deshalb schenken wir Ihnen als Sofortpreis 3 Ausgaben der «Schweizer Familie». Und natürlich haben Sie immer noch die Chance auf unseren Hauptpreis: drei Nächte im historischen Hotel La Couronne im Herzen der Solothurner Altstadt.',
        },
      },
      {
        count: '3',
        text: {
          headline: 'Nicht schlecht, aber da ist noch Luft nach oben.',
          paragraph:
            'Man kann nicht alles wissen, denn die Schweiz ist so vielseitig, dass man immer wieder Neues entdeckt. Deshalb schenken wir Ihnen als Sofortpreis 3 Ausgaben der «Schweizer Familie». Und natürlich haben Sie immer noch die Chance auf unseren Hauptpreis: drei Nächte im historischen Hotel La Couronne im Herzen der Solothurner Altstadt.',
        },
      },
      {
        count: '4',
        text: {
          headline: 'Sie kennen sich aus!',
          paragraph:
            'Gratulation zu diesem Resultat. Doch die Schweiz ist so vielseitig, dass man immer noch etwas Neues entdecken kann. Deshalb schenken wir Ihnen als Sofortpreis 3 Ausgaben der «Schweizer Familie». Und natürlich haben Sie die Chance auf unseren Hauptpreis: drei Nächte im historischen Hotel La Couronne im Herzen der Solothurner Altstadt.',
        },
      },
      {
        count: '5',
        text: {
          headline: 'Besser geht’s nicht!',
          paragraph:
            'Gratulation zu Ihrem Spitzen-Resultat. Doch die Schweiz ist so vielseitig, dass man trotzdem immer noch etwas Neues entdecken kann. Deshalb schenken wir Ihnen als Sofortpreis 3 Ausgaben der «Schweizer Familie». Und natürlich haben Sie die Chance auf unseren Hauptpreis: drei Nächte im historischen Hotel La Couronne im Herzen der Solothurner Altstadt.',
        },
      },
    ],
    resultResolution: [
      {
        step: '1',
        text:
          'Störche klappern, teils unter halsbrecherischen Verrenkung, um sich zu begrüssen, aber auch zum Verjagen von Eindringlingen. Daher haben sie auch ihren Zweitnamen: Klapperstorch. Weitere Interessante Tierreportagen finden Sie jede Woche in der «Schweizer Familie»',
      },
      {
        step: '2',
        text:
          'Das originale Birchermüesli wurde von seinem Erfinder «Apfeldiätspeise» genannt und jeweils abends serviert. Feine Rezepte für jede Tageszeit finden Sie immer donnerstags in der «Schweizer Familie».',
      },
      {
        step: '3',
        text:
          'Als UNICEF-Botschafterin unterstützt Stefanie Heinzmann auch die Sternenwochen, wo Kinder Kindern helfen. Spannende Interviews mit interessanten Persönlichkeiten finden Sie jeden Donnerstag in der «Schweizer Familie».',
      },
      {
        step: '4',
        text:
          'Das Wasser des Giessbachs ergiesst sich über insgesamt 14 Stufen in den türkisblauen Brienzersee. Geniessen Sie jede Woche eindrückliche Bilder der schönsten Orte in der «Schweizer Familie».',
      },
      {
        step: '5',
        text:
          'In seinem neusten Abenteuer verschlägt es Globi ins Landesmuseum Zürich. Auch in echt immer ein Besuch wert. Die schönsten Ausflugstipps für Klein und Gross finden Sie Woche für Woche in der «Schweizer Familie».',
      },
    ],
  },
  getters: {
    getQuestion: (state) => (step) => {
      return state.questions[step];
    },
    getAnswers: (state) => (quizStep) => {
      return state.answers.find((answer) => answer.step === String(quizStep));
    },
    getUserAnswers: (state) => {
      return state.userAnswers;
    },
    getResultText: (state) => (trueAnswersCount) => {
      return state.resultText.find(
        (text) => text.count === String(trueAnswersCount)
      );
    },
    getResultResolution: (state) => {
      return state.resultResolution;
    },
    getCampId: (state) => {
      return state.campId;
    },
  },
  mutations: {
    setAnswer(state, { answer }) {
      state.userAnswers.push(answer);
    },
    resetAnswers(state) {
      state.userAnswers = [];
    },
    setCampId(state, { campId }) {
      state.campId = campId;
    },
  },
  actions: {
    setAnswer(context, answer) {
      context.commit('setAnswer', answer);
    },
    resetAnswers(context) {
      context.commit('resetAnswers');
    },
    setCampId(context, campId) {
      context.commit('setCampId', campId);
    },
  },
  modules: {},
});
