<template>
  <ul class="share-list">
    <li class="share-list__item share-list__item--facebook">
      <a
        :href="shareLinks.facebook"
        target="_blank"
        title="Facebook"
      >
        <facebook-icon />
      </a>
    </li>
    <li class="share-list__item share-list__item--mail">
      <a
        :href="shareLinks.mail"
        target="_blank"
        title="Email"
      >
        <email-icon />
      </a>
    </li>
    <li class="share-list__item share-list__item--whatsapp">
      <a
        :href="shareLinks.whatsApp"
        data-action="share/whatsapp/share"
        target="_blank"
        title="WhatsApp"
      >
        <whats-app-icon />
      </a>
    </li>
  </ul>
</template>

<script>
  import Facebook from './icons/Facebook'
  import WhatsApp from './icons/WhatsApp'
  import Email from './icons/Email'

  export default {
    name: 'Share',
    components: {
      FacebookIcon: Facebook,
      WhatsAppIcon: WhatsApp,
      EmailIcon: Email
    },
    props: {
      scoreCount: {
        type: Number,
        required: true
      }
    },
    computed: {
      shareLinks() {
        return {
          facebook: 'https://www.facebook.com/sharer/sharer.php?u=https://schweizer-quiz.ch',
          whatsApp: `whatsapp://send?text=Ich habe beim Quiz der «Schweizer Familie» ${this.scoreCount} von 5 Frage richtig beantwortet. Und wie gut kennst du die Schweiz? Mach mit und gewinne einen von 100 Sofortpreisen und nimm an der Verlosung für drei Übernachtungen im historischen Hotel La Couronne im Herzen der Solothurner Altstadt teil: https://schweizer-quiz.ch`,
          mail: `mailto:?subject=Teste dein Schweizer Wissen!&body=Hallo, ich habe beim Quiz der «Schweizer Familie» ${this.scoreCount} von 5 Frage richtig beantwortet. Und wie gut kennst du die Schweiz? Mach mit und gewinne einen von 100 Sofortpreisen und nimm an der Verlosung für drei Übernachtungen im historischen Hotel La Couronne im Herzen der Solothurner Altstadt teil: https://schweizer-quiz.ch`
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../styles/Share";
</style>
