<template>

  <div class="BackgroundImage">
    <!-- <img class="BackgroundImage__image" :src="require(`@/assets/backgrounds/${this.imageName}.png`)" alt="Hintergrundbild" /> -->
    <picture class="BackgroundImage__picture">
      <source :srcset="require(`@/assets/backgrounds/Frage${this.imageName}_2048x1536.jpg`)" media="(min-width: 1280px">
      <source :srcset="require(`@/assets/backgrounds/Frage${this.imageName}_1024x768.jpg`)" media="(min-width: 1024px">
      <source :srcset="require(`@/assets/backgrounds/Frage${this.imageName}_768x1024.jpg`)" media="(min-width: 0px">
      <img :src="require(`@/assets/backgrounds/Frage${this.imageName}_2048x1536.jpg`)" alt="Hintergrundbild" >
    </picture>
    <div class="BackgroundImage__overlay"></div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  props: {
    imageName: {
      default: '2'
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/BackgroundImage.scss";
</style>
