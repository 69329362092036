import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Quiz from '../views/Quiz.vue'
import Impressum from '../views/Impressum.vue'
import Result from '../views/Result.vue'
import ThankYou from '../views/ThankYou.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/quiz/:step',
    name: 'Quiz',
    component: Quiz
  },
  {
    path: '/ergebnis',
    name: 'Result',
    component: Result
  },
  {
    path: '/danke',
    name: 'ThankYou',
    component: ThankYou
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
