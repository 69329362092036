<template>
  <div class="Hero">
    <div class="fullscreen-bg">
      <video loop muted playsinline autoplay poster="../assets/video/Home_hoch.jpg" class="fullscreen-bg__video fullscreen-bg__video--mobile">>
          <source class="fullscreen-bg__video--mobile" src="../assets/video/Homevideo_hoch.mp4" type="video/mp4">
      </video>
      <video loop muted autoplay poster="../assets/video/Home_quer.jpg" class="fullscreen-bg__video fullscreen-bg__video--desktop">
          <source class="fullscreen-bg__video--desktop" src="../assets/video/Homevideo_quer.mp4" type="video/mp4">
      </video>
    </div>
    <div class="Hero__content">
      <h1 class="Hero__headline">Testen Sie Ihr Wissen über die Schweiz</h1>
      <p>
        und gewinnen Sie drei Übernachtungen für Zwei im historischen Hotel La Couronne in der charmanten Altstadt von Solothurn und einen von 100 Sofortpreisen.
      </p>
      <img class="Hero__image" src="../assets/verlosung.svg" alt="Verlosung" />
      <div class="Hero__button Hero__button--desktop">
      <Button link="/quiz/1" text="Quiz starten" :router-link="true" />
    </div>
    </div>
    <div class="Hero__button Hero__button--mobile">
      <Button link="/quiz/1" text="Quiz starten" :router-link="true" />
    </div>
  </div>
</template>

<script>
import Button from './Button'

export default {
  name: "Hero",
  components: {
    Button
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/Hero.scss";
</style>
