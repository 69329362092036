<template functional>
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M35 17.6052C34.9996 14.2609 34.041 10.9868 32.2376 8.17043C30.4343 5.35407 27.8617 3.1134 24.8244 1.7137C21.7872 0.314006 18.4125 -0.186109 15.0998 0.272566C11.7872 0.731242 8.67531 2.1295 6.13265 4.3018C3.58999 6.47411 1.723 9.32948 0.752731 12.5299C-0.217542 15.7303 -0.250473 19.1417 0.657837 22.3602C1.56615 25.5788 3.37766 28.4697 5.87791 30.6907C8.37816 32.9116 11.4624 34.3697 14.7656 34.8922V22.6641H10.3221V17.6052H14.7656V13.7493C14.7656 9.36412 17.379 6.94037 21.3748 6.94037C22.6875 6.9592 23.9971 7.07325 25.2933 7.28162V11.5895H23.0854C22.4208 11.5013 21.7483 11.6806 21.2158 12.0881C20.6834 12.4956 20.3346 13.0978 20.246 13.7625C20.221 13.9486 20.2171 14.1369 20.2344 14.3239V17.6052H25.0833L24.3075 22.6641H20.2242V34.8922C24.3426 34.2432 28.0939 32.1452 30.8028 28.9759C33.5117 25.8067 35.0001 21.7744 35 17.6052Z" fill="#232323"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="35" height="35" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {
    name: 'Facebook'
  }
</script>
