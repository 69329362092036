<template>
  <div class="Footer">
    <ul class="Footer__items">
      <li class="Footer__item">
        <a class="Footer__link" href="tel:+41444046363">044 404 63 63</a>
      </li>
      <li class="Footer__item">
        <a
          class="Footer__link"
          target="_blank"
          href="https://abo.schweizerfamilie.ch/tamstorefront/contact"
          >Kontakt</a
        >
      </li>
      <li class="Footer__item"><a class="Footer__link" href="https://www.schweizerfamilie.ch/agb">AGB</a></li>
      <li class="Footer__item">
        <a class="Footer__link" target="_blank" href="https://www.schweizerfamilie.ch/datenschutz">Datenschutzbestimmungen</a>
      </li>
      <li class="Footer__item">
        <a class="Footer__link" target="_blank" href="https://www.schweizerfamilie.ch/impressum">Impressum</a>
      </li>
    </ul>

    <div class="Footer__copyright">© 2023 TX Group AG</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@import "../styles/Footer.scss";
</style>
