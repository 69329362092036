<template>
  <div>
    <router-link class="Logo" to="/">
      <img src="../assets/logo-red.svg" alt="Logo">
    </router-link>
    <Result />
    <Footer />
  </div>
</template>

<script>
import Result from '../components/Result.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Result,
    Footer
  }
}
</script>
