<template>
  <div>
    <router-link class="Logo" to="/">
      <img src="../assets/logo.svg" alt="Logo">
    </router-link>
    <Hero />
    <Footer />
  </div>
</template>

<script>
import Hero from '../components/Hero.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    Footer
  },
  mounted() {
    this.$store.dispatch({
     type: 'resetAnswers'
    });
    if (this.$route.query.campid) {
      this.$store.dispatch({
      type: 'setCampId',
      campId: this.$route.query.campid
    });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
