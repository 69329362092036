<template functional>
  <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M0.87793 35L3.33917 26.0127C1.82102 23.3827 1.02216 20.3989 1.0234 17.3422C1.02723 7.77968 8.80992 0 18.3724 0C23.0133 0.00185208 27.3693 1.80813 30.6447 5.08627C33.9202 8.36443 35.7231 12.7219 35.7213 17.356C35.7174 26.9186 27.9341 34.6993 18.3726 34.6993H18.3726H18.3655C15.4618 34.6981 12.6088 33.9699 10.0749 32.5883L0.87793 35ZM10.5009 29.4484L11.0275 29.7608C13.2414 31.0743 15.7792 31.7691 18.3666 31.7701H18.3725C26.3203 31.7701 32.7889 25.3035 32.7921 17.355C32.7936 13.5032 31.2952 9.88143 28.5727 7.15674C25.8502 4.43205 22.2297 2.93065 18.3783 2.92918C10.4244 2.92918 3.95574 9.39533 3.95256 17.3433C3.95145 20.067 4.71383 22.7197 6.15724 25.0149L6.50014 25.5602L5.0434 30.8796L10.5009 29.4484Z" fill="#232323"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0364 10.0922C13.6845 9.24714 13.3271 9.36147 13.0611 9.34823C12.8085 9.33566 12.5192 9.33301 12.2302 9.33301C11.9413 9.33301 11.4716 9.44145 11.0743 9.87526C10.6769 10.3091 9.55713 11.3575 9.55713 13.4905C9.55713 15.6234 11.1104 17.6841 11.3272 17.9733C11.5439 18.2625 14.3839 22.6397 18.7324 24.5168C19.7666 24.9632 20.5741 25.2298 21.2036 25.4296C22.2421 25.7594 23.1871 25.7129 23.934 25.6013C24.7668 25.4769 26.4987 24.5529 26.8599 23.5407C27.2211 22.5284 27.2211 21.6608 27.1128 21.48C27.0044 21.2993 26.7154 21.1908 26.282 20.9739C25.8484 20.757 23.7172 19.7085 23.3198 19.564C22.9225 19.4194 22.6336 19.3471 22.3446 19.7809C22.0556 20.2147 21.2248 21.1908 20.972 21.48C20.7191 21.7692 20.4663 21.8054 20.0328 21.5885C19.5993 21.3716 18.2025 20.9139 16.5468 19.4376C15.2582 18.2886 14.3881 16.8693 14.1353 16.4355C13.8824 16.0017 14.1084 15.7671 14.3254 15.5511C14.5204 15.357 14.7588 15.045 14.9756 14.7919C15.1923 14.5389 15.2646 14.3581 15.4091 14.0689C15.5536 13.7797 15.4813 13.5266 15.3729 13.3097C15.2646 13.0928 14.3976 10.9598 14.0364 10.0922Z" fill="#232323"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="35" height="35" fill="white" transform="translate(0.799805)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {
    name: 'WhatsApp'
  }
</script>
