<template>
  <div>
    <router-link class="Logo" to="/">
      <img src="../assets/logo.svg" alt="Logo">
    </router-link>
    <Quiz />
    <Footer />
  </div>
</template>

<script>
import Quiz from '../components/Quiz.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Quiz,
    Footer
  }
}
</script>
