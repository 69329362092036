<template>
  <div>
    <router-link class="Logo" to="/">
      <img src="../assets/logo-red.svg" alt="Logo">
    </router-link>
    <ThankYou />
    <Footer />
  </div>
</template>

<script>
import ThankYou from '../components/ThankYou.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'home',
  components: {
    ThankYou,
    Footer
  }
}
</script>
