<template>
  <div class="Result">
    <div class="Result__content">
      <div class="Result__score-container">
        <img :src="require(`@/assets/gifs/Murmeli${this.trueAnswersCount}.gif`)" class="Result__score-image" alt="Murmeltier">
        <span class="Result__score">{{ trueAnswersCount }} von 5 Fragen richtig</span>
      </div>

      <h1>{{ resultText.text.headline }}</h1>
      <div class="Result__accordion" :class="accordionClasses">
        <div class="Result__accordion-headline" @click="toggleAccordion">
          <span>Sehen Sie hier Ihre Resultate.</span>
          <img src="@/assets/icons/arrow-down.svg" />
        </div>
        <div class="Result__accordion-body">
          <article v-for="result in resultResolution" :key="result.step" class="Result__step">
            <div v-if="userAnswers[result.step - 1] === true" class="Result__step--correct">Frage {{ result.step }} Richtig</div>
            <div v-else class="Result__step--wrong">Frage {{ result.step }} Falsch</div>
            <div class="Result__step-text">
              <img class="Result__step-image" :src="require(`@/assets/result-container/${result.step}.png`)" alt="Zeitschrift">
              <p class="Result__step-paragraph">{{ result.text }}</p>
              </div>
          </article>
        </div>
      </div>

      <div class="Result__contest">
        <h2 class="Result__contest-headline">Gratulation!  Sie haben einen Sofortpreis gewonnen</h2>
        <div class="Result__contest-container">
          <div class="sofortpreis_wrap">
            <div class="sofortpreis_content sofortpreis_bubbleanimation">
              <div class="sofortpreis_cover sofortpreis_cover03 sofortpreis_cover03animation"></div>
              <div class="sofortpreis_cover sofortpreis_cover02 sofortpreis_cover02animation"></div>
              <div class="sofortpreis_cover sofortpreis_cover01 sofortpreis_cover01animation"></div>
            </div>
          </div>
          <div class="Result__contest-teaser">
            <p>{{ resultText.text.paragraph }}</p>
            Sofortpreis abholen und an der Verlosung teilnehmen:
            <Button
              css-class="Button Button--sm"
              text="Jetzt mitmachen"
              link="#contact-form"
            />
          </div>
        </div>

        <h2 class="Result__contest-headline">Gewinnen Sie mit der «Schweizer Familie» drei Nächte im historischen Hotel La Couronne im Herzen der Solothurner Altstadt.</h2>

        <div class="Result__contest-image-container">
          <img src="../assets/hotel.gif" alt="Maiensässhotel Guarda Val" class="Result__contest-image" />
          <p class="Result__contest-text">
            <strong class="Result__contest-text--bold">Wir verlosen drei Übernachtungen für 2 Personen in einem Boutique-Doppelzimmer mit Frühstück, inklusive</strong>
            <ul class="Result__contest-bullets">
              <li class="Result__contest-bullet">Welcome Drink in der Bar à Vin</li>
              <li class="Result__contest-bullet">zwei 3-Gang-Abendmenüs mit Weinbegleitung im Le Restaurant (14 Gault-Millau-Punkte)</li>
              <li class="Result__contest-bullet">eine private Altstadtführung</li>
            </ul>
          </p>
        </div>
      </div>

      <div class="ContactForm" id="contact-form">
        <Form />
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./Form.vue";
import Button from "./Button";

export default {
  name: "Result",
  components: {
    Button,
    Form,
  },
  data() {
    return {
      isOpen: false,
      results: [],
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    // prepareResults(){
    //   for(let i = 0; i < this.userAnswers.length; i++) {
    //     console.log(this.$store.getters.getAnswers(i + 1));
    //     console.log(this.userAnswers[i]);
    //     this.userAnswers[i].find(rightAnswer)
    //   }
    // }
  },
  computed: {
    userAnswers() {
      // prevents user from having more right answers than possible by going back and clicking on the right answer multiple times.
      return this.$store.getters.getUserAnswers.slice(0, 5);
    },
    trueAnswersCount() {
      return this.userAnswers.filter((answer) => answer === true).length;
    },
    resultText() {
      return this.$store.getters.getResultText(this.trueAnswersCount);
    },
    accordionClasses() {
      return {
        "is-closed": !this.isOpen,
        "is-open": this.isOpen,
      };
    },
    resultResolution() {
      return this.$store.getters.getResultResolution;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/Result.scss";
</style>
