<template>
  <div>
    <router-link v-if="routerLink === true" :class="cssClass" :to="link">{{ text }}</router-link>
    <a v-if="routerLink === false" :class="cssClass" :href="link">{{ text }}</a>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false
    },
    routerLink: {
      type: Boolean,
      default: false
    },
    cssClass: {
      type: String,
      default: 'Button'
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/Button.scss";
</style>
