<template functional>
  <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M35.2003 15.6041C35.2229 17.5291 34.8887 19.4417 34.2145 21.2449C32.9895 24.4183 30.6255 26.7137 27.248 26.7137C26.2998 26.7524 25.3612 26.5112 24.5492 26.02C23.7372 25.5288 23.0878 24.8094 22.682 23.9516C21.8753 24.7996 20.9093 25.48 19.8393 25.954C18.7692 26.428 17.6161 26.6861 16.4461 26.7137C15.3456 26.7723 14.245 26.5997 13.2152 26.2071C12.1854 25.8146 11.2492 25.2107 10.467 24.4343C7.53718 21.3718 7.29218 14.6285 11.1524 10.917C14.2907 7.89974 18.3974 7.81953 22.7155 8.50786C23.9553 8.68661 25.1778 8.96956 26.3701 9.35369L25.8772 19.8216C25.8772 21.8049 26.4202 22.7966 27.5061 22.7966C28.42 22.7966 29.1492 22.1292 29.6936 20.7943C30.2936 19.1173 30.5702 17.3418 30.5089 15.5618C30.5509 13.4459 30.0578 11.3536 29.0753 9.47911C28.0802 7.73487 26.6182 6.30289 24.8536 5.34423C23.0891 4.38558 21.0921 3.93828 19.0872 4.05265C16.4673 3.98485 13.8743 4.59468 11.5593 5.82307C6.95385 8.38682 4.95885 13.3277 4.95885 18.6885C4.84849 20.3343 5.07165 21.9854 5.61494 23.5428C6.15823 25.1003 7.01045 26.532 8.12052 27.752C12.723 32.3531 21.5372 31.1762 27.6345 28.9406V33.0516C24.4077 34.383 20.9442 35.0458 17.4538 34.9999C12.024 34.9999 7.79093 33.5727 4.75468 30.7183C1.71843 27.8638 0.200308 23.8952 0.200308 18.8124C0.0796252 15.5321 0.847416 12.2802 2.42253 9.40027C3.99764 6.52035 6.32155 4.11947 9.14864 2.4514C12.1724 0.777171 15.5848 -0.0685146 19.0405 -5.67753e-05C24.8738 -5.67753e-05 30.3076 2.33328 33.1863 7.40682C34.5638 9.91578 35.2583 12.7425 35.2003 15.6041ZM13.2538 18.2816C13.2538 21.2955 14.4827 22.8024 16.9405 22.8024C20.0701 22.8024 20.878 20.1512 21.0982 17.0216L21.3768 12.2922C20.566 12.1259 19.7398 12.0462 18.9122 12.0545C15.1657 12.0516 13.2538 14.6095 13.2538 18.2816Z" fill="#232323"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="35" height="35" fill="white" transform="translate(0.200195)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {
    name: 'Email'
  }
</script>
