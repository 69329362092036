<template>
  <div>
    <!--<FormulateForm name="form" v-model="formValues" autocomplete="off">
      <h2>Teilnahmeformular</h2>
      <FormulateInput
        :wrapper-class="['radio-buttons']"
        type="radio"
        name="salutation"
        :options="{ 11: 'Herr', 12: 'Frau' }"
        error-behavior="value"
        validation="required"
        v-model="salutation"
        :validation-messages="{
          required: 'Wählen Sie bitte die passende Anrede aus.',
        }"
      />
      <FormulateInput
        type="text"
        name="lastName"
        placeholder="Name*"
        autocomplete="off"
        error-behavior="value"
        validation="required"
        :validation-messages="{
          required: 'Geben Sie Ihren Namen ein.',
        }"
      />
      <FormulateInput
        type="text"
        name="firstName"
        placeholder="Vorname*"
        autocomplete="off"
        error-behavior="value"
        validation="required"
        :validation-messages="{
          required: 'Geben Sie Ihren Vornamen ein.',
        }"
      />
      <FormulateInput
        type="group"
        name="streetGroup"
        :wrapper-class="['input-group--street']"
      >
        <FormulateInput
          type="text"
          name="street"
          placeholder="Strasse*"
          error-behavior="value"
          :validation="['required', ['matches', /^[^\d]+$/]]"
          :validation-messages="{
            required: 'Geben Sie Ihre Strasse ein.',
            matches: 'Geben Sie Ihre Strasse ohne Nummer ein.',
          }"
          v-model="formattedStreetName"
          @blur="formatStreetName"
        />
        <FormulateInput
          type="text"
          name="number"
          placeholder="Nr*"
          error-behavior="value"
          validation="required"
          :validation-messages="{
            required: 'Geben Sie Ihre Strassennummer ein.',
          }"
        />
        <FormulateInput
          type="text"
          name="additionalNumber"
          placeholder="Nr-Zusatz"
        />
      </FormulateInput>
      <FormulateInput
        type="group"
        name="cityGroup"
        :wrapper-class="['input-group--city']"
      >
        <FormulateInput
          type="text"
          name="zip"
          placeholder="PLZ*"
          error-behavior="value"
          validation="required"
          :validation-messages="{
            required: 'Geben Sie Ihre Postleitzahl ein.',
          }"
        />
        <FormulateInput
          type="text"
          name="city"
          placeholder="Ort*"
          error-behavior="value"
          validation="required"
          :validation-messages="{
            required: 'Geben Sie Ihren Ort ein.',
          }"
        />
        <FormulateInput type="text" name="country" v-model="country" disabled />
      </FormulateInput>
      <FormulateInput type="text" name="phone" placeholder="Telefonnummer" />
      <FormulateInput
        type="text"
        name="email"
        placeholder="E-Mail-Adresse*"
        error-behavior="value"
        validation="required|email"
        :validation-messages="{
          required: 'Geben Sie Ihre Email Adresse ein.',
          email: 'Geben Sie eine gültige Email Adresse ein.',
        }"
      />
      <div class="checkboxes">
        <FormulateInput
          name="dispense"
          type="checkbox"
          label="Ich möchte nur an der Verlosung teilnehmen und verzichte auf den Sofortpreis von 3 kostenlosen Ausgaben der «Schweizer Familie»."
        />
        <FormulateInput
          name="terms"
          type="checkbox"
          error-behavior="value"
          validation="accepted"
          :validation-messages="{
            accepted:
              'Stimmen Sie bitte den Allgemeinen Geschäftsbedingungen zu',
          }"
        >
          <div
            slot="label"
            class="formulate-input-label formulate-input-label--after text-bold"
          >
            Ich habe die
            <a
              class="link"
              target="_blank"
              href="https://www.schweizerfamilie.ch/agb"
              >Allgemeinen Geschäftsbedingungen</a
            >
            der TX Group AG gelesen und bin damit einverstanden.*
          </div>
        </FormulateInput>
      </div>
      <FormulateInput
        class="form-button"
        type="submit"
        label="Ja, ich will gewinnen."
        @click="submitForm"
      />
      <div class="ContactForm__note">
        Die Verlosungsteilnahme ist unabhängig von einer Bestellung oder eines
        bestehenden Abonnements.<br />
        Die Teilnehmenden der Verlosung erhalten automatisch den wöchentlichen,
        kostenlosen Newsletter der «Schweizer Familie» (Abmeldung jederzeit
        möglich). Teilnahmeschluss ist der 30.09.2024. Die Gewinnerin/der
        Gewinner werden persönlich benachrichtigt. Eine Barauszahlung ist nicht
        möglich. Über die Verlosung wird keine Korrespondenz geführt. Der
        Rechtsweg ist ausgeschlossen. Die Mitarbeitenden der TX-Group AG und
        deren Tochtergesellschaften sind von einer Teilnahme ausgeschlossen.
      </div>
    </FormulateForm>-->
    <h2>
      Die Verlosung ist abgeschlossen. Die Gewinnerin/der Gewinner werden
      persönlich benachrichtigt. Vielen Dank an alle Teilnehmenden.
    </h2>
  </div>
</template>

<script>
export default {
  name: 'Form',
  components: {},
  data() {
    return {
      formValues: {},
      newsletterValue: 'nein',
      dispenseValue: 'nein',
      salutation: '',
      formattedPhoneNumber: '',
      formattedStreetName: '',
      country: 'Schweiz', // Default country - read only
    };
  },
  methods: {
    formatPhoneNumber() {
      this.formattedPhoneNumber = this.formValues.phone
        .replace('+41', '')
        .replace('+43', '')
        .replace('+49', '');
      this.formattedPhoneNumber = this.formattedPhoneNumber.replace(/\D/g, ''); // Remove any chars and return only number
      this.formattedPhoneNumber = Number(this.formattedPhoneNumber); // removes leading 0
    },
    formatStreetName() {
      // Replace str. -> strasse
      this.formattedStreetName = this.formattedStreetName.replace(
        'str.',
        'strasse'
      );
    },
    submitForm() {
      if (!this.formValues.cityGroup[0].city) {
        return false;
      }
      if (!this.formValues.email) {
        return false;
      }
      if (!this.formValues.lastName) {
        return false;
      }
      if (!this.formValues.firstName) {
        return false;
      }
      if (this.formattedStreetName === '') {
        return false;
      }
      if (!this.formValues.streetGroup[0].number) {
        return false;
      }
      if (!this.formValues.salutation) {
        return false;
      }
      if (!this.formValues.cityGroup[0].zip) {
        return false;
      }
      if (!this.formValues.terms) {
        return false;
      }
      if (this.formValues.newsletter) {
        this.newsletterValue = 'ja';
      }
      if (this.formValues.dispense) {
        this.dispenseValue = 'ja';
      }

      // format phone number
      if (this.formValues.phone) {
        this.formatPhoneNumber();
      }

      //const apiEndpoint = 'https://api.schweizer-quiz.ch/items/subscriber'
      const apiEndpoint =
        'https://quiz-api.schweizerfamilie.ch/items/subscriber';
      //const apiEndpoint = 'http://0.0.0.0:8055/items/subscriber'

      const data = {
        city: this.formValues.cityGroup[0].city,
        email: this.formValues.email,
        last_name: this.formValues.lastName,
        name: this.formValues.firstName,
        street: this.formValues.streetGroup[0].street,
        street_number: this.formValues.streetGroup[0].number,
        additional_street_nr: this.formValues.streetGroup[0].additionalNumber,
        phone: this.formattedPhoneNumber,
        salutation: this.salutation,
        zip_code: this.formValues.cityGroup[0].zip,
        newsletter: this.newsletterValue,
        dispense: this.dispenseValue,
        kavz: this.$store.getters.getCampId
          ? this.$store.getters.getCampId
          : '',
        promotion: this.formValues.dispense ? '' : 'SFINX062',
        contact_lang: 'D',
        advertising_type: 'NW',
        saled_offert: this.formValues.dispense ? '' : '21003.1',
        country_code: 'CH',
      };

      fetch(apiEndpoint, {
        method: 'POST',
        headers: [['Content-Type', 'application/json']],
        body: JSON.stringify(data),
      })
        .then(() => {
          this.$router.push({ name: 'ThankYou' });
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>
<style lang="scss">
@import '../styles/Form.scss';
</style>
