<template>
  <div class="ThankYou">
    <div class="ThankYou__content">
      <div class="ThankYou__score-container">
        <img
          :src="require(`@/assets/gifs/Murmeli${this.trueAnswersCount}.gif`)"
          class="ThankYou__score-image"
          alt="Murmeltier"
        />
        <span class="ThankYou__score"
          >{{ trueAnswersCount }} von 5 Fragen richtig</span
        >
      </div>

      <h1>Danke für Ihre Teilnahme.</h1>
      <p class="ThankYou__text">Die Verlosung findet am 30.09.2024 statt. Sollten Sie gewonnen haben, informieren wir Sie gerne per E-Mail.</p>
      <p class="ThankYou__text">Falls nicht anders angegeben, erhalten Sie als Sofortpreis in spätestens 14 Tagen die «Schweizer Familie» während 3 Wochen kostenlos in Ihren Briefkasten.</p>
      <p class="ThankYou__text">Viel Glück!</p>
      <div class="ThankYou__button">
        <Button link="https://www.schweizerfamilie.ch" css-class="Button Button--sm" text="Zu schweizerfamilie.ch" />
      </div>
    </div>

    <div class="ThankYou__share">
      <p class="ThankYou__share-text">Quiz teilen und Freunde herausfordern:</p>
      <share :score-count="trueAnswersCount"/>
    </div>
  </div>
</template>

<script>
import Button from './Button.vue'
import Share from './Share'

export default {
  components: {
    Button,
    Share
  },
  computed: {
    userAnswers() {
      // prevents user from having more right answers than possible by going back and clicking on the right answer multiple times.
      return this.$store.getters.getUserAnswers.slice(0, 5);
    },
    trueAnswersCount() {
      return this.userAnswers.filter((answer) => answer === true).length;
    },
  },
}
</script>

<style lang="scss">
@import "../styles/ThankYou.scss";
</style>
