<template>
  <div class="Quiz">
    <background-image :imageName="step"/>

    <div class="Quiz__content">
      <div class="Quiz__step">{{ step }} / 5</div>

      <div class="Quiz__question">
        {{ question }}
      </div>

      <div class="Quiz__answers" v-if="answers">
      <div
        v-for="(answer, index) in answers.options"
        :key="index"
        class="Quiz__button"
        @click="nextPage(answer)"
      >
        <Button :text="answer.value" />
      </div>
    </div>

    </div>
    <div class="Quiz__answers" v-if="answers">
      <div
        v-for="(answer, index) in answers.options"
        :key="index"
        class="Quiz__button"
        @click="nextPage(answer)"
      >
        <Button :text="answer.value" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button";
import BackgroundImage from "./BackgroundImage";

export default {
  name: "Hero",
  components: {
    Button,
    BackgroundImage,
  },
  computed: {
    question() {
      return this.$store.getters.getQuestion(this.step);
    },
    answers() {
      return this.$store.getters.getAnswers(this.step);
    },
    nextStep() {
      if (this.lastStep) {
        return false;
      }

      return this.step + 1;
    },
    lastStep() {
      return this.step === 5;
    },
    step() {
      return Number(this.$route.params.step);
    },
  },
  methods: {
    nextPage(answer) {

       this.$store.dispatch({
        type: 'setAnswer',
        answer: answer.rightAnswer
      })
      
      if (this.lastStep || isNaN(this.nextStep)) {
        this.$router.push({ name: 'Result' });

        return false;
      }

      this.$router.push(`/quiz/${this.nextStep}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/Quiz.scss";
</style>
